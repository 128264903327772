@import "breakpoints";

:root {
  --app-width: calc(min(100vw, 1200px));
  --color: #454545;
  --horizontal-item-column-count: 1;
  --default-gap: 1.5rem;
  --horizontal-item-gap: var(--default-gap);
  --white: #ffffff;
  --secondary-color: #4392f1;

  @media screen and (min-width: 361px) and (max-width: 960px) {
    --horizontal-item-column-count: 2;
  }
  @media screen and (min-width: 961px) {
    --horizontal-item-column-count: 4;
  }


  // make use of --app-width and --horizontal-item-column-count, including --horizontal-item-gap
  // for the height, the aspect ratio is 16:9, so the height is 9/16 of the width
  --grid-block-width: calc((var(--app-width) - (var(--horizontal-item-column-count) - 1) * var(--horizontal-item-gap)) / var(--horizontal-item-column-count));
  --grid-block-image-height: calc(var(--grid-block-width) * 9 / 16);

  --listing-filters-width: calc(min(100vw, 300px));

  --listings-gap: var(--horizontal-item-gap);

  --listing-width: var(--grid-block-width);
  ////--listing-width: calc(50vw / 2 - var(--listings-gap) * .5);
  //@include breakpoint(md) {
  //  @include portrait() {
  //    --listing-width: calc((100vw - var(--listing-filters-width)) / 2 - (var(--listings-gap) * .5));
  //  }
  //  @include landscape() {
  //    --listing-width: calc((100vw - var(--listing-filters-width)) / 3 - (var(--listings-gap) * .666666667));
  //  }
  //}
  //@include breakpoint(lg) {
  //  --listing-width: calc((100vw - var(--listing-filters-width)) / 4 - (var(--listings-gap) * .75));
  //}
}
