.uwcasa--spinner {
  @keyframes uwcasa--custom-spinner-color-animation {
    100%, 0% {
      stroke: var(--primary-color);
    }
    50% {
      stroke: var(--secondary-color);
    }
  }
}
