body:has(.top-bar) {
  --top-bar-height: 60px;

  //padding-top: var(--top-bar-height);

  .top-bar {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--top-bar-height);
    //background: #fff;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;

    .logo-wrapper {
      height: calc(var(--top-bar-height) * .5);
        display: flex;
      img {
        object-fit: cover;
        height: 100%;
      }
    }
  }
}
